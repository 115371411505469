<template>
  <div>
    <user-verified></user-verified>
    <the-navigation v-if="screenWidth > 600"></the-navigation>
    <div class="mainDashboard d-flex flex-column justify-content-between min-vh-100">
      <router-view></router-view>
    </div>
    <nav-mobile v-if="screenWidth <= 600"></nav-mobile>
  </div>
</template>

<script>
import Navigation from "../components/templates/Navigation/Navigation.vue";
import CheckUserIsVerified from "../components/templates/Dashboard/user_verification/CheckUserIsVerified.vue";
import NavigationMobileView from "../components/templates/Navigation/NavigationMobileView.vue";

export default {
  async created() {
    await window.addEventListener("resize", this.handleResize);
    await this.handleResize();
    const token = localStorage.getItem("token");

    if (!token) {
      this.$store.dispatch("logoutUser");
    }
  },
  components: {
    "the-navigation": Navigation,
    "user-verified": CheckUserIsVerified,
    navMobile: NavigationMobileView,
  },
  data() {
    return {
      screenWidth: 0,
    };
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>
