<template>
  <div>
    <div
      class="instituteListDropdown px-3 py-2 h-100 d-flex flex-column justify-content-between"
    >

      <div>
        <b-list-group :style="$screenSize > 600 ? 'width: 250px' : ''">
          <b-list-group-item
            v-if="Object.keys(filteredUniqueInstitutions).length == 0"
          >
            No Institution Found!
          </b-list-group-item>
          <b-list-group-item
            v-for="(institute, index) in filteredUniqueInstitutions"
            :key="index"
            v-else
          >
            <div class="w-100">
              <div class="d-flex justify-content-between align-items-baseline">
                <p
                  class="btn p-0 m-0"
                  @click="setDefaultInstitutionUserId(institute.id)"
                >
                  {{ institute.name }}
                </p>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="createInstitute">
        <div id="instituteStatus" class="p-0 m-0">
          <p
            class="text-danger"
            v-if="
              !instituteApprovalStatus &&
                Object.keys(filteredUniqueInstitutions).length != 0
            "
            style="font-size: 12px"
          >
            Cannot create new institute since one institute is in pending state
          </p>
          <b-button
            variant="primary"
            size="sm"
            v-if="
              instituteApprovalStatus &&
                Object.keys(filteredUniqueInstitutions).length != 0
            "
            @click="enableInstituteCreaction"
            >Create Institute</b-button
          >
        </div>
      </div>
    </div>
    <!-- </b-sidebar> -->
  </div>
</template>

<script>
export default {
  computed: {
    filteredUniqueInstitutions() {
      return this.$store.getters.getFilteredUniqueInstitutions;
    },
    instituteApprovalStatus() {
      return this.$store.getters.getInstitutions.institionApproval;
    },
    institutionUsers() {
      return this.$store.getters.getInstitutionUsers;
    },
  },

  methods: {
    enableInstituteCreaction() {
      this.$store.commit("enableInstituteCreation");
    },
    async setDefaultInstitutionUserId(institutionId) {
      let institutionUserId = this.institutionUsers[institutionId].userIds[0];
      await this.$store.dispatch(
        "setDefaultInstitutionUserId",
        institutionUserId
      );
      await this.$store.dispatch("setUpInstitutes");
    },
  },
};
</script>

<style >
.navbar .instituteListDropdown .list-group .list-group-item {
  padding: 5px;
  border-bottom: 2px solid #d2d2d2;
  font-weight: 600;
}

.navbar .instituteListDropdown .list-group .list-group-item:hover {
  background-color: #1ea2ed;
  color: #ffffff !important;
}

.navbar .instituteListDropdown .list-group .list-group-item p:hover {
  color: #ffffff !important;
}
</style>
