<template>
  <div>
    <div class="notifications">
      <h6>Notifications</h6>
      <b-list-group>
        <!-- <b-list-group-item>
          <p class="p-0 m-0">Notification 1</p>
          <small>Notification Details</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Notification 2</p>
          <small>Notification Details</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Notification 3</p>
          <small>Notification Details</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Notification 4</p>
          <small>Notification Details</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Notification 5</p>
          <small>Notification Details</small>
        </b-list-group-item> -->
        <b-list-group-item
          v-for="(notification, notificationIndex) in notifications"
          :key="notificationIndex"
        >
          <p class="p-0 m-0">{{ notification.data }}</p>
        </b-list-group-item>
      </b-list-group>
      <!-- <a
        href="#"
        class="d-flex justify-content-end align-items-baseline text-dark text-decoration-none"
      >
        Show More <i class="fas fa-caret-right ml-1"></i>
      </a> -->
    </div>
    <br />
    <!-- <div class="onlineCoursesForYou">
      <h6>Online Courses For You</h6>
      <b-list-group>
        <b-list-group-item>
          <p class="p-0 m-0">Fundamentals of Cloud Computing</p>
          <small>Offered by Organization Name</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Digital Marketing Certifications</p>
          <small>Offered by Organization Name</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Digital Marketing Certifications</p>
          <small>Offered by Organization Name</small>
        </b-list-group-item>
      </b-list-group>
      <a
        href="#"
        class="d-flex justify-content-end align-items-baseline text-dark text-decoration-none"
      >
        Show More <i class="fas fa-caret-right ml-1"></i>
      </a>
      <br />
      <h6>Jobs Matching Your Skills</h6>
      <b-list-group>
        <b-list-group-item>
          <p class="p-0 m-0">Digital Marketing Consultant</p>
          <small>Posted by Organization Name</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Digital Marketing Consultant</p>
          <small>Posted by Organization Name</small>
        </b-list-group-item>
        <b-list-group-item>
          <p class="p-0 m-0">Digital Marketing Consultant</p>
          <small>Posted by Organization Name</small>
        </b-list-group-item>
      </b-list-group>
      <a
        href="#"
        class="d-flex justify-content-end align-items-baseline text-dark text-decoration-none"
      >
        Show More <i class="fas fa-caret-right ml-1"></i>
      </a>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.getUserNotifications();
  },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    getUserNotifications() {
      const url = this.$store.getters.getAPIKey.mainAPI + "/user/notifications";
      axios.get(url, this.enrollmentCode).then((response) => {
        this.notifications = response.data;
      });
    },
  },
};
</script>
<style >
.notifications .list-group-item p {
  font-family: "Roboto Regular";
  font-size: 14px;
}
</style>
