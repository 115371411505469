<template>
  <div>
    <div>
      <b-modal
        id="chechUserVerification"
        title="BootstrapVue"
        no-close-on-backdrop
        no-close-on-keyboard
        no-close-on-esc
        hide-header-close
        hide-header
        hide-footer
        size="lg"
      >
        <div class="verificationCheck">
          <b-container fluid>
            <div class="emailVerify" style="border: 1px solid #d3d3d3">
              <div class="bg-light p-4">
                <h6
                  class=""
                  style="border-bottom-color: 1px solid #d3d3d3"
                  v-if="userData.email_verified_at == null"
                >
                  Verify Your Email Address
                  <span class="ml-2 text-warning">(Pending)</span>
                </h6>
                <h6
                  class="text-success"
                  style="border-bottom-color: 1px solid #d3d3d3"
                  v-else
                >
                  Email Verified
                  <i class="far fa-check-circle text-success"></i>
                </h6>
                <h6 class="d-flex align-items-center">
                  {{ userData.email }}
                  <i
                    class="far fa-edit btn p-0 m-0 ml-1"
                    v-if="!emailEdit"
                    @click="emailEdit = true"
                  ></i>
                </h6>
              </div>

              <b-form class="pl-5 pr-5" @submit.prevent="changeEmail()" v-if="emailEdit">
                <b-form-group>
                  <h6
                    class="alert alert-success text-center"
                    v-if="responseSuccess.created"
                  >
                    {{ responseSuccess.created[0] }}
                  </h6>
                </b-form-group>
                <b-form-group label="Enter New Email">
                  <b-form-input
                    class="w-50"
                    v-model="email"
                    :class="{ 'is-invalid': responseErrors.email }"
                  ></b-form-input>
                  <span class="text-danger" v-if="responseErrors.email">{{
                    responseErrors.email[0]
                  }}</span>
                </b-form-group>
                <b-form-group>
                  <b-button
                    variant="primary"
                    type="submit"
                    size="sm"
                    :disabled="updateEmail.disabled"
                    >{{ updateEmail.text }}</b-button
                  >
                  <b-button
                    variant="danger"
                    size="sm"
                    :disabled="updateEmail.disabled"
                    class="ml-3"
                    @click="cancel()"
                    >Cancel</b-button
                  >
                </b-form-group>
              </b-form>
              <div v-if="!emailEdit">
                <p class="p-4" v-if="userData.email_verified_at == null">
                  Before proceeding please check your email for verification link. If you
                  did not recieve the email or the link has expired,
                  <a
                    :class="
                      resendMail.disabled
                        ? 'btn p-0 w-0 text-info disabled'
                        : 'btn p-0 w-0 text-info'
                    "
                    @click="resendEmail"
                    >{{ resendMail.text }}</a
                  >.
                </p>
                <p class="p-4 text-success" v-else>
                  Your email is successfully verified.
                </p>
                <h6 class="alert alert-success font-weight-bolder" v-if="resendEmailSent">
                  Verification Link sent to {{ userData.email }}
                </h6>
                <h6 class="alert alert-danger" v-if="resendEmailFailed">
                  Something went wrong! Please try again after
                  <vue-countdown
                    :time="60000"
                    v-slot="{ seconds }"
                    @end="endCountdownEmail"
                  >
                    {{ seconds }} seconds.
                  </vue-countdown>
                </h6>
              </div>
            </div>

            <div class="mobileVerify mt-3" style="border: 1px solid #d3d3d3">
              <div class="bg-light p-4">
                <h6
                  style="border-bottom-color: 1px solid red"
                  v-if="userData.mobile_verified_at == null"
                >
                  Verify Your Mobile Number
                  <span class="ml-2 text-warning">(Pending)</span>
                </h6>

                <h6 style="border-bottom-color: 1px solid red" v-else>
                  Mobile Number Verified
                  <i class="far fa-check-circle text-success"></i>
                </h6>
                <h6 class="d-flex align-items-center">
                  {{ userData.mobile }}
                  <i
                    class="far fa-edit btn p-0 m-0 ml-1"
                    v-if="!mobileEdit"
                    @click="mobileEdit = true"
                  ></i>
                </h6>
              </div>

              <div v-if="mobileEdit">
                <b-form class="pl-5 pr-5" @submit.prevent="changeMobile()">
                  <b-form-group>
                    <h6
                      class="alert alert-success text-center"
                      v-if="responseSuccess.created"
                    >
                      {{ responseSuccess.created[0] }}
                    </h6>
                  </b-form-group>
                  <b-form-group label="Enter New Mobile">
                    <b-form-input
                      class="w-50"
                      v-model="mobile"
                      placeholder="Enter 10 digit mobile number."
                      :class="{ 'is-invalid': responseErrors.mobile }"
                    ></b-form-input>
                    <span class="text-danger" v-if="responseErrors.mobile">{{
                      responseErrors.mobile[0]
                    }}</span>
                  </b-form-group>
                  <b-form-group>
                    <b-button
                      variant="primary"
                      type="submit"
                      size="sm"
                      :disabled="updateEmail.disabled"
                      >{{ updateEmail.text }}</b-button
                    >
                    <b-button
                      variant="danger"
                      size="sm"
                      :disabled="updateEmail.disabled"
                      class="ml-3"
                      @click="cancel()"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </div>
              <div v-else>
                <div class="p-4">
                  <b-form
                    @submit.prevent="verifyMobile()"
                    v-if="userData.mobile_verified_at == null"
                  >
                    <b-form-group
                      label="One Time Password"
                      label-for="input-1"
                      id="input-group-1"
                      class="d-flex align-items-baseline"
                      ><div>
                        <b-input
                          type="text"
                          class="ml-3"
                          placeholder="Enter OTP..."
                          v-model="otpToSent"
                        ></b-input
                        ><b-button
                          type="submit"
                          variant="primary"
                          id="mobileSubmitBtn"
                          class="mt-2 ml-3"
                          :disabled="verifySms.disabled"
                          >{{ verifySms.text }}</b-button
                        >
                      </div></b-form-group
                    >
                  </b-form>
                  <h6 class="alert alert-success" v-if="mobileVerify">
                    Mobile Verified successfully
                  </h6>
                  <h6 class="alert alert-danger" v-if="mobileVerifyFailed">Wrong OTP!</h6>

                  <div v-if="userData.mobile_verified_at == null">
                    <hr />
                    <p v-if="!requestOtpAfterCountdown">
                      Before proceeding please check your SMS inbox for OTP. If you did
                      not receive the SMS containing the OTP,
                      <a
                        class="btn p-0 w-0 text-info"
                        @click="otpSent"
                        :disabled="resendSms.disabled"
                        >{{ resendSms.text }}</a
                      >
                    </p>
                    <p class="text-dark" v-else>
                      <span class="alert alert-success" v-if="countdownTime == 120000">
                        Wait for
                        <vue-countdown
                          :time="countdownTime"
                          v-slot="{ minutes, seconds }"
                          @end="endCountdown"
                        >
                          {{ minutes }} minutes, {{ seconds }} seconds.
                        </vue-countdown>
                        before you can request another.</span
                      >
                      <span class="alert alert-danger" v-else>
                        {{ resendSms.text }}
                        <vue-countdown
                          :time="countdownTime"
                          v-slot="{ seconds }"
                          @end="endCountdown"
                        >
                          {{ seconds }} seconds.
                        </vue-countdown>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="logoutButton text-right">
              <b-button variant="danger" class="text-white" size="md" @click="logoutUser"
                >Logout</b-button
              >
            </div>
          </b-container>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  async created() {
    await this.getUserDetails();
    if (
      this.userData.email_verified_at != null &&
      this.userData.mobile_verified_at != null
    ) {
      // window.location.reload();
    } else {
      this.$bvModal.show("chechUserVerification");
    }
  },
  components: {
    VueCountdown,
  },
  data() {
    return {
      requestOtpAfterCountdown: false,
      countdownTime: 120000,
      requestOtpAfterCountdownEmail: false,
      countdownTimeEmail: 120000,
      userData: {
        email: "",
        id: null,
        email_verified_at: null,
        mobile_verified_at: null,
        username: "",
        mobile: "",
      },
      email: "",
      updateEmail: {
        text: "Submit",
        disabled: false,
      },
      mobile: "",
      emailEdit: false,
      mobileEdit: false,
      userVerified: false,
      sendOtp: false,
      resendOtp: "",
      resendEmailSent: false,
      resendEmailFailed: false,
      otpToSent: "",
      mobileVerify: false,
      mobileVerifyFailed: false,
      mobileVerified: false,
      resendMail: {
        text: "click here to request another",
        disabled: false,
      },
      resendSms: {
        text: "click here to request another",
        disabled: false,
      },
      verifySms: {
        text: "Verify",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
    };
  },
  methods: {
    endCountdown() {
      this.countdownTime = 120000;
      this.resendSms.text = "click here to request another.";
      this.resendSms.disabled = false;
      this.requestOtpAfterCountdown = false;
    },
    endCountdownEmail() {
      this.resendMail.text = "click here to request another";
      this.resendMail.disabled = false;
      this.resendEmailFailed = !this.resendEmailFailed;
    },
    changeEmail() {
      this.updateEmail.text = "Please wait...";
      this.updateEmail.disabled = true;
      const url = this.$store.getters.getAPIKey.changeEmail;
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.email == "") {
        this.responseErrors.email = ["Email field cannot be empty!"];
        return this.clearResponse();
      } else if (!this.email.match(mailformat)) {
        this.responseErrors.email = ["Please enter a valid email address!"];
        return this.clearResponse();
      }
      const updatedEmail = {
        email: this.email,
      };
      axios
        .post(url, updatedEmail)
        .then((response) => {
          this.responseSuccess["created"] = [
            `Email changed to ${this.email}. Verification link sent.`,
          ];
          this.resendEmail();
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },
    changeMobile() {
      this.updateEmail.text = "Please wait...";
      this.updateEmail.disabled = true;
      const url = this.$store.getters.getAPIKey.changeMobile;
      if (this.mobile == "") {
        this.responseErrors.mobile = ["Mobile field cannot be empty!"];
        return this.clearResponse();
      } else if (isNaN(this.mobile) || this.mobile.length < 10) {
        this.responseErrors.mobile = ["Please enter a valid mobile number!"];
        return this.clearResponse();
      }

      const updatedMobile = {
        mobile: "91" + this.mobile,
      };

      axios
        .post(url, updatedMobile)
        .then((response) => {
          this.responseSuccess["created"] = [
            `Mobile Number changed to ${this.mobile}. OTP sent.`,
          ];
          this.otpSent();
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },
    clearResponse() {
      this.updateEmail.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.email = "";
          this.mobile = "";
          this.responseSuccess = {};
          this.emailEdit = false;
          this.mobileEdit = false;
        }
        this.responseErrors = {};
        this.updateEmail.disabled = false;
      }, 3000);
    },
    cancel() {
      this.email = "";
      this.mobile = "";
      this.responseSuccess = {};
      this.responseErrors = {};
      this.emailEdit = false;
      this.mobileEdit = false;
    },
    logoutUser() {
      this.$store.dispatch("logoutUser");
    },
    async resendEmail() {
      // if (this.emailEdit) {
      this.resendMail.text = "Sending...";
      this.resendMail.disabled = true;
      // }

      const url = this.$store.getters.getAPIKey.reVerifyEmail;

      const th = this;
      try {
        const response = await axios.post(url);
        if (response) {
          response;
          this.resendMail.text = "click here to request another";
          this.resendMail.disabled = false;
          if (this.emailEdit) {
            this.resendEmailSent = !this.resendEmailSent;
            setTimeout(() => {
              th.resendEmailSent = !th.resendEmailSent;
            }, 3000);
          }
        }
      } catch (error) {
        if (error.response) {
          this.resendMail.text = "click here to request another";
          // this.resendMail.disabled = false;
          this.resendEmailFailed = !this.resendEmailFailed;
          error;
        }
        // error;
      }
    },
    async otpSent() {
      this.resendSms.text = "Please wait...";
      this.resendSms.disabled = true;
      this.sendOtp = !this.sendOtp;
      const url = this.$store.getters.getAPIKey.reVerifyMobile;
      // const th = this;
      axios
        .post(url)
        .then(() => {
          this.resendSms.text = "click here to request another.";
          this.resendSms.disabled = false;
          this.requestOtpAfterCountdown = true;
        })
        .catch((error) => {
          if (error.response) {
            this.countdownTime = 60000;
            this.resendSms.text = "Something went wrong please try after ";
            this.resendSms.disabled = false;
            this.requestOtpAfterCountdown = true;
          }

          error;
        });
    },
    async verifyMobile() {
      this.verifySms.text = "Please Wait...";
      this.verifySms.disabled = true;
      const url = this.$store.getters.getAPIKey.verifyMobile;

      axios
        .post(url, { otp: this.otpToSent })
        .then((response) => {
          if (response.status == 204) {
            this.verifySms.text = "Verified";
            // this.verifySms.disabled = false;
            this.otpToSent = "";
            this.mobileVerified = !this.mobileVerified;
            setTimeout(() => {
              window.location.reload();
              // this.mobileVerifyFailed = !this.mobileVerifyFailed,
              this.mobileVerified = !this.mobileVerified;
            }, 3000);
          }
        })
        .catch((error) => {
          this.verifySms.text = "Try Again";

          this.otpToSent = "";
          if (error.response) {
            this.mobileVerifyFailed = !this.mobileVerifyFailed;
            setTimeout(() => {
              this.verifySms.disabled = false;
              this.mobileVerifyFailed = !this.mobileVerifyFailed;
            }, 3000);
          }
        });
    },
    async getUserDetails() {
      try {
        const url = this.$store.getters.getAPIKey.userDetails;
        const response = await axios.get(url);
        if (response) {
          this.userData.email = response.data.email;
          this.userData.id = parseInt(response.data.id);
          this.userData.email_verified_at = response.data.email_verified_at;
          this.userData.mobile_verified_at = response.data.mobile_verified_at;
          this.userData.username = response.data.username;
          this.userData.mobile = response.data.mobile;
        }
        response;
      } catch (error) {
        if (error.response) {
          const userDetailsProblem = "Something Went Wrong";
          alert(userDetailsProblem);
          this.logoutUser();
        }
      }
    },
  },
};
</script>
