<template>
  <div>
    <div class="navMobile">
      <b-navbar toggleable="lg" fixed="bottom">
        <!-- <b-navbar-brand to="/home"> ezygo.app </b-navbar-brand> -->
        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav> -->
        <!-- <b-navbar-nav>
          <b-nav-form>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text bg-white"
                  ><i class="fas fa-search"></i
                ></span>
              </div>
              <b-form-input
                type="text"
                size="sm"
                class="mr-sm-2 form-control"
                placeholder="Search"
              ></b-form-input>
            </div>
          </b-nav-form>
        </b-navbar-nav> -->

        <b-navbar-nav>
          <b-nav-item to="/home">
            <div class="d-flex flex-column text-center">
              <em
                ><span><i class="fas fa-home"></i></span
              ></em>
            </div>
          </b-nav-item>

          <b-nav-item-dropdown dropup>
            <template #button-content>
              <em><i class="fas fa-university"></i></em>
            </template>
            <b-dropdown-item><InstituteListDropdown /></b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- <b-nav-item-dropdown dropright>
            <template #button-content>
              <span><i class="fas fa-chalkboard-teacher fa-2x text-dark"></i></span>
              <br />

              <em>Online Courses</em>
            </template>
            <b-dropdown-item
              ><router-link to="/courses" class="text-decoration-none text-dark"
                >Courses</router-link
              ></b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item-dropdown dropright>
            <template #button-content>
              <span><i class="far fa-edit fa-2x text-dark"></i></span> <br />
              <em class="text-center">Online Exams</em>
            </template>
          </b-nav-item-dropdown>
          <b-nav-item>
            <span><i class="fas fa-briefcase fa-2x text-dark"></i></span> <br />
            <em>Jobs/Internships</em>
          </b-nav-item>
          <b-nav-item>
            <span><i class="fas fa-comment fa-2x text-dark"></i></span> <br />
            <em>Messaging</em>
          </b-nav-item> -->

          <!-- <b-nav-item>
            <span><i class="fas fa-bell fa-2x text-dark"></i></span> <br />
            <em>Notifications</em>
          </b-nav-item> -->

          <b-nav-item-dropdown dropup>
            <template #button-content>
              <em><i class="fas fa-bell"></i></em>
            </template>
            <b-dropdown-item>
              <Notifications />
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown dropup>
            <template #button-content>
              <em>
                <i class="fas fa-user-circle"></i>
              </em>
            </template>
            <b-dropdown-item :to="{ name: 'profile' }"
              >My profile</b-dropdown-item
            >
            <b-dropdown-item @click="logoutUser">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <!-- </b-collapse> -->
      </b-navbar>
    </div>
  </div>
</template>

<script>
import InstituteListDropdown from "../Dashboard/Institute/InstituteListDropdown.vue";
import Notifications from "../Dashboard/Notifications.vue";

import axios from "axios";
export default {
  async created() {
    await this.checkUserProfile();
  },
  data() {
    return {
      username: "",
    };
  },
  components: {
    InstituteListDropdown,
    Notifications,
  },
  methods: {
    logoutUser() {
      this.$store.dispatch("logoutUser");
    },
    async checkUserProfile() {
      const url = this.$store.getters.getAPIKey.checkUserProfile;
      axios.get(url).then((response) => {
        if (
          response.data.first_name != null &&
          response.data.last_name != null
        ) {
          this.username = response.data.first_name;
        } else {
          this.username = "No Name";
        }
        this.fetchData = false;
      });
    },
  },
};
</script>

<style >
.navMobile .navbar {
  background-color: #2962ff;
}

.navMobile .nav-item i {
  font-size: 18px;
  color: white !important;
}
.navMobile .nav-item em i {
  font-size: 28px !important;
  color: white !important;
}

.navMobile .navbar .navbar-nav img {
  width: 34px;
  height: 34px;
  margin: 0 auto;
  display: block;
}


.navMobile .navbar .nav-item {
  outline-color: transparent;
  outline-style: none;
}

.navMobile .navbar .nav-item em {
  font-family: "Roboto Regular" !important;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  color: #414040;
}


.navMobile .navbar-brand {
  font-family: "ErasITC";
  font-size: 24px !important;
  color: white !important;
}

.navMobile .navbar .nav-item {
  text-align: center;
}


.navMobile nav .navbar-nav {
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between;
}

.navMobile .navbar .nav-item {
  text-align: none !important;
}


@media only screen and (max-width: 600px) and (orientation: portrait) {
  .navMobile .navbar .navbar-nav img {
    margin: none;
    display: inline-block;
  }


  .navMobile .navbar input {
    width: auto;
    height: auto;
    border-left-style: solid;
  }

  .navMobile .navbar .nav-item br {
    display: none;
  }

}
</style>
