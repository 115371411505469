<template>
  <div>
    <b-card v-if="getLoding">
      <b-skeleton animation="wave" width="100%"></b-skeleton>
      <b-skeleton animation="wave" width="100%"></b-skeleton>
    </b-card>
    <b-navbar toggleable="lg" v-else>
      <b-navbar-brand to="/home"><img src="../../../assets/images/login1.png" style="width: 50px; height: 50px" alt=""
          class="img-fluid" /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto" style="margin-right: 130px">
          <b-nav-item @click="
            $route.name != 'institute'
              ? $router.push({ name: 'institute' })
              : ''
          ">
            <span><i class="fas fa-home fa-2x"></i></span>
            <br />
            <em>Home </em></b-nav-item>
          <b-nav-item-dropdown>
            <template #button-content>
              <span><i class="fas fa-university fa-2x"></i></span> <br />
              <em class="ml-2">{{
                  institute.id && institute.name ? institute.code : "Institute"
              }}</em>
            </template>
            <b-dropdown-group>
              <InstituteListDropdown />
            </b-dropdown-group>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template #button-content>
              <span><i class="fas fa-bell fa-2x"></i></span> <br />
              <em class="ml-2">Notifications</em>
            </template>
            <b-dropdown-item>
              <Notifications />
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown class="profile-dropdown">
            <template #button-content>
              <span>
                <i class="fas fa-user-circle fa-2x" v-if="profile.profile_image == null"></i>

                <img v-else :src="
                  cloudStorageRoot +
                  profile.profile_image.file_path +
                  profile.profile_image.file_name
                " alt="profile" class="rounded-circle" style="width: 30px; height: 30px" />
              </span>
              <br v-if="profile.profile_image == null" />
              <em class="ml-2">
                {{ profile.id ? profile.first_name : "Name" }}
              </em>
            </template>
            <b-dropdown-item @click="
              $route.name != 'MyProfile'
                ? $router.push({ name: 'MyProfile' })
                : ''
            ">My profile</b-dropdown-item>
            <b-dropdown-item @click="logoutUser">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="enableHelp" @click="openHelpInNewTab">
            <span><i class="fas fa-question fa-2x"></i></span>
            <br />
            <em>Help</em></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import acl from "../../../authorization/acl";
import InstituteListDropdown from "../Dashboard/Institute/InstituteListDropdown.vue";
import Notifications from "../Dashboard/Notifications.vue";
export default {
  async created() {

    this.fetchData = true;
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;
    await this.$store.dispatch("getMyProfile");
    await this.$store.dispatch("getUser");
    try {
      const studentRole = await acl.isUserGranted("viewStudentTab");
      this.enableHelp = !studentRole;
    } catch (error) {
      error;
    }
    this.fetchData = false;
  },
  components: {
    InstituteListDropdown,
    Notifications,
  },
  data() {
    return {
      enableHelp: true,
      cloudStorageRoot: null,
      username: "",
      fetchData: false,
      mainProps: {
        width: 30,
        height: 30,
      },
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getMyProfile;
    },
    getLoding() {
      return this.$store.getters.getInstituteStatus;
    },
    institute() {
      return this.$store.getters.getCurrentInstitution;
    },
  },
  methods: {
    openHelpInNewTab() {
      window.open(this.$router.resolve({ name: 'helpAndQuestions' }).href, '_blank');
    },
    async logoutUser() {
      await this.$store.dispatch("logoutUser");
    },
  },
};
</script>

<style >
.navbar .navbar-nav .profile-menu .dropdown-menu {
  padding: 5px;
  border-bottom: 2px solid #d2d2d2;
  font-weight: 600;
  background-color: red !important;
}

.navbar .profile-menu .dropdown-menu .dropdown-item:hover {
  background-color: #1ea2ed;
  color: #ffffff !important;
}

.navbar {
  background-color: #1ea2ed;
  padding: 5px !important;
}

.nav-item span i {
  font-size: 28px !important;
  color: white;
}

.nav-item em {
  color: white !important;
}

.navbar .navbar-nav img {
  width: 34px;
  height: 34px;
  margin: 0 auto;
  display: block;
}

.navbar .nav-item {
  font-family: "Roboto Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #414040;
}

.navbar .nav-item em {
  font-family: "Roboto Regular" !important;
  font-size: 14px !important;
  font-weight: 600;
  font-style: normal;
  color: #414040;
}

.navbar-brand {
  font-family: "ErasITC";
  font-size: 24px !important;
  color: white !important;
}

.navbar .nav-item {
  text-align: center;
}

.navbar .navbar-nav .profile-dropdown .dropdown-menu {
  width: 250px;
}

.navbar .navbar-nav .profile-dropdown .dropdown-menu .dropdown-item {
  border-bottom: 2px solid #d2d2d2;
}

.navbar .navbar-nav .profile-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #1ea2ed;
  color: #ffffff !important;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .navbar .navbar-nav img {
    margin: none;
    display: inline-block;
  }

  .navbar .nav-item br {
    display: none;
  }
}
</style>
